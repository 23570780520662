import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import Header from './header/header'
import './layout.css'
import News from './news/news'
import Footer from './footer/footer'
import Banner from './banner/banner'
import ContactBanner from './contact-banner/contact-banner'
import CookieConsent from 'react-cookie-consent'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <ContactBanner />
        <Header siteTitle={data.site.siteMetadata.title} />

        <div className="banner__wrapper">
          <div className="wrapper" style={{ flexDirection: `column` }}>
            <Banner />
          </div>
        </div>
        <main
          style={{
            backgroundColor: `#ECF0F1`,
            display: `flex`,
            justifyContent: `center`,
          }}
        >
          <div className="wrapper">
            <div className="content">{children}</div>
            <News />
          </div>
        </main>
        <Footer />
        <CookieConsent
          buttonText="Begrepen"
          cookieName="hasCookiesAccepted"
          expires={150}
          containerClasses="cookie__container"
          buttonClasses="cookie__button"
        >
          Deze website gebruikt cookies om het gebruik van de site te
          vergemakkelijken.
          <b>
            <Link to="/cookiebeleid"> Meer weten over cookies?</Link>{' '}
          </b>
        </CookieConsent>
      </>
    )}
  />
)

export default Layout
