import React from 'react'

import styles from './footer.module.css'

const Footer = () => (
  <footer className={styles.footer}>
    <p>
      <strong>Yves De Vrient: </strong> Verwarming en Sanitair
    </p>
    <p>
      © 2015 - {new Date().getFullYear()} | Alle rechten voorbehouden - Gemaakt
      door{' '}
      <a
        href="https://jonasdevrient.be"
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        Jonas De Vrient
      </a>
    </p>
  </footer>
)

export default Footer
