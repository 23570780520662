import React from 'react'

import styles from './contact-banner.module.css'

import phoneIcon from '../../images/icons/phone.svg'
import emailIcon from '../../images/icons/email.svg'
import adressIcon from '../../images/icons/adress.svg'

const ContactBanner = () => (
  <div className={styles.banner}>
    <div className={`wrapper ${styles.banner__wrapper}`}>
      <div
        style={{
          display: `flex`,
        }}
      >
        <div className={styles.item}>
          <img
            className={styles.banner__icon}
            src={phoneIcon}
            height="0.9rem"
            width="0.9rem"
            alt="phone icon"
          />
          <p>09 384 97 89</p>
        </div>
        <div className={styles.item}>
          <img
            className={styles.banner__icon}
            src={emailIcon}
            alt="email icon"
          />
          <p>yves@yvesdevrient.be</p>
        </div>
      </div>
      <div
        className={styles.item}
        style={{
          marginRight: `0`,
        }}
      >
        <img
          className={styles.banner__icon}
          src={adressIcon}
          alt="address icon"
        />
        <p>Kruishoutemsesteenweg 259 - Kruisem</p>
      </div>
    </div>
  </div>
)
export default ContactBanner
