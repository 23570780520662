import React, { Component } from 'react'

import { Link } from 'gatsby'

import styles from './menu.module.css'

import HamburgerMenu from 'react-hamburger-menu'

class Menu extends Component {
  state = {
    links: [
      {
        id: 1,
        path: `/`,
        text: `Home`,
      },
      {
        id: 2,
        path: `/over`,
        text: `Over`,
      },
      {
        id: 3,
        path: `/verwarming`,
        text: `Verwarming`,
      },
      {
        id: 4,
        path: `/sanitair`,
        text: `Sanitair`,
      },
      {
        id: 5,
        path: `/realisaties`,
        text: `Realisaties`,
      },
      {
        id: 6,
        path: `/contact`,
        text: `Contact`,
      },
    ],
    open: false,
  }

  render() {
    return (
      <div>
        <div
          className={styles.mobile__menu}
          onClick={this.handleClick.bind(this)}
        >
          <p>Menu</p>
          <div style={{ marginRight: `15px` }}>
            <HamburgerMenu
              isOpen={this.state.open}
              menuClicked={this.handleClick.bind(this)}
              width={22}
              height={16}
              trokeWidth={1}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.45}
            />
          </div>
        </div>
        <menu>
          <nav>
            <ul
              className={
                styles.list +
                ' ' +
                (this.state.open ? '' : styles.list__collapse)
              }
            >
              {this.state.links.map(link => {
                return (
                  <li key={link.id}>
                    <Link
                      activeStyle={
                        !this.state.open
                          ? {
                              fontWeight: `600`,
                              color: `var(--primary)`,
                            }
                          : {}
                      }
                      to={link.path}
                      className={styles.list__item}
                    >
                      {link.text}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </menu>
      </div>
    )
  }

  handleClick() {
    this.setState({
      open: !this.state.open,
    })
  }
}

export default Menu
