import React from 'react'

import Logo from '../logo/logo'
import Menu from '../menu/menu'

import styles from './header.module.css'

const Header = () => (
  <header className={styles.header}>
    <div
      className="wrapper"
      style={{
        justifyContent: `space-between`,
        alignItems: `center`,
      }}
    >
      <Logo />
      <Menu />
    </div>
  </header>
)

export default Header
