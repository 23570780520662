import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import styles from './banner.module.css'

const Banner = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "yvesdevrient-banner.jpg" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        alt="banner Yves De Vrient"
        className={styles.banner}
      />
    )}
  />
)
export default Banner
