import React from 'react'
import styles from './logo.module.css'
import logo from '../../images/logo-transparent.svg'
import { Link } from 'gatsby'

const Logo = () => (
  <div className={styles.item}>
    <Link to="/">
      <img
        src={logo}
        alt="logo Yves De Vrient"
        className={styles.logo}
        height="85px"
        width="85px"
      />
    </Link>
    <h2 className={styles.logo__title}>Yves De Vrient</h2>
  </div>
)
export default Logo
