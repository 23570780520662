import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './news.module.css'

const News = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulNews {
            edges {
              node {
                id
                titel
                childContentfulNewsBeschrijvingRichTextNode {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const newsItems = data.allContentfulNews.edges

        return (
          <div className={styles.news}>
            <h2 className={styles.section__name}>Nieuws</h2>

            {newsItems.map(node => {
              const newsItem = node.node

              return (
                <div key={newsItem.id}>
                  <h3 className={styles.news__title}>{newsItem.titel}</h3>
                  <div
                    className={styles.news__body}
                    dangerouslySetInnerHTML={{
                      __html:
                        newsItem.childContentfulNewsBeschrijvingRichTextNode
                          .childContentfulRichText.html,
                    }}
                  />
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default News
